/* eslint-disable @typescript-eslint/no-unused-vars */
/* Framework imports -------------------------------------------------------- */
import * as RTKQuery from '@reduxjs/toolkit/query/react'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { processRequest } from 'helpers/fetchHelpers'
import { apiUrl } from 'helpers/readConfig'

/* Type imports ------------------------------------------------------------- */
import type {
  MissionnementMotifRefus,
  MissionnementMotifsRefusListParams,
  Anomalie,
  AnomalieDossierCreatePayload,
  AnomalieDossierUpdatePayload,
  AnomalieType,
  AnomalieTypeUpdatePayload,
  AnomalieTypeCreatePayload,
  LoginResponse,
  Dossier,
  WorkflowEntreprise,
  CodeLabel,
  RendezVousMetreurRequest,
  RendezVousTravauxRequest,
  TauxTVA,
  LoginRequest,
  ResultatRechercheDossier,
  RechercheDossierListParams,
  CompagnieAssurance,
  NatureSinistre,
  Devis,
  DevisRequest,
  PiedDevis,
  ImportDevisCreatePayload,
  Eurogex,
  CoordoneesRequest,
  PriseDeContactRequest,
  Bordereau,
  Document,
  ElementMediatheque,
  MediathequeDossierDetailParams,
  DocumentDossierDetailParams,
  EditerDevisCreateParams,
  MissionnementEtatRequest,
  FactureAPI,
  FactureRequest,
  PiedFacture,
  EditerFactureCreateParams,
  ImportFactureCreatePayload,
  ValidationProblemDetails,
  ResetPasswordExtranetEntrepriseAuthentificationCreatePayload,
  EchangeInterne,
  EchangeInterneListParams,
  DestinatairesEchangeInterneDetailParams,
  NouveauEchangeInterneCreatePayload,
  RepondreEchangeInterneCreatePayload,
  TransfertEchangeInterneCreatePayload,
  InterventionDossierCreatePayload,
  InterventionMotifNonReparation,
  ResultatInterventionEnumLabel,
  OrigineDemandeInterventionDiffereeEnumLabel,
  DocumentDossierCreatePayload,
  MediathequeDossierCreatePayload,
  BrouillonDevisDetailParams,
  CompteRenduVisiteSinappsCreatePayload,
  EnumerationsMetier,
  LoginIrenVerrueAuthentificationCreateParams,
  NestedCodeLabel,
  BrouillonFactureDetailParams,
  CompteRenduRdfSinappsCreatePayload,
  ComplexiteDDEEnumLabel,
  CongeEtablissementCreatePayload,
  EntreprisePartenaire,
  EntreprisePartenaireUpdatePayload,
  IdLabel,
} from 'API/__generated__/Api'

/* API Redux service -------------------------------------------------------- */
export const api = RTKQuery.createApi(
  {
    reducerPath: 'api',
    baseQuery: RTKQuery.fetchBaseQuery({ baseUrl: apiUrl }) as RTKQuery.BaseQueryFn<string | RTKQuery.FetchArgs, unknown, ValidationProblemDetails>,
    tagTypes: [
      'AUTH',
      'MESSAGES',
      'INFO',
      'WORKFLOW',
      'WORKS_RDV',
      'MEASURE_RDV',
      'MEDIAS',
      'ISSUES',
      'COMPANY',
    ],
    endpoints: (build) => {
      return {
        /* Authentification */
        authenticate: build.mutation<LoginResponse, LoginRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.loginExtranetEntrepriseAuthentificationCreate,
                args,
              )
            },
          },
        ),
        // authenticateExternal: build.mutation<LoginResponseDossier, LoginIrenExtranetAuthentificationCreateParams>(
        //   {
        //     queryFn: async (args) => {
        //       return processRequest(
        //         OpenAPI.authentification.loginIrenExtranetAuthentificationCreate,
        //         args,
        //       )
        //     },
        //   },
        // ),
        authenticateExternal: build.mutation<LoginResponse, LoginIrenVerrueAuthentificationCreateParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.loginIrenVerrueAuthentificationCreate,
                args,
              )
            },
          },
        ),
        resetPassword: build.mutation<void, ResetPasswordExtranetEntrepriseAuthentificationCreatePayload>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.resetPasswordExtranetEntrepriseAuthentificationCreate,
                args,
              )
            },
          },
        ),
        /* Issue */
        getIssuesList: build.query<Anomalie[], string>({
          queryFn: async (args, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.dossier.anomaliesDossierDetail,
              args,
            )
          },
          providesTags: (result, error, arg) => [
            { type: 'ISSUES' },
          ],
        }),
        getIssue: build.query<Anomalie, number>({
          queryFn: async (args, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.dossier.anomalieDossierDetail,
              args,
            )
          },
        }),
        postIssue: build.mutation<void, {caseId: string; data: AnomalieDossierCreatePayload}>({
          queryFn: async (args, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.dossier.anomalieDossierCreate,
              args.caseId,
              args.data,
            )
          },
          invalidatesTags: [ 'ISSUES', 'WORKFLOW' ],
        }),
        patchIssue: build.mutation<void, {id: number; data: AnomalieDossierUpdatePayload}>({
          queryFn: async (args, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.dossier.anomalieDossierUpdate,
              args.id,
              args.data,
            )
          },
          invalidatesTags: [ 'ISSUES', 'WORKFLOW' ],
        }),
        /* Issue Type */
        getIssueTypesList: build.query<AnomalieType[], void>({
          queryFn: (arg, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.anomalieTypes.anomalieTypesList)
          },
        }),
        getIssueType: build.query<AnomalieType, number>({
          queryFn: (arg, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.anomalieType.anomalieTypeDetail,
              arg,
            )
          },
        }),
        patchIssueType: build.mutation<void, {id: number;data: AnomalieTypeUpdatePayload}>({
          queryFn: (args, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.anomalieType.anomalieTypeUpdate,
              args.id,
              args.data,
            )
          },
          invalidatesTags: [ 'ISSUES' ],
        }),
        postIssueType: build.mutation<void, AnomalieTypeCreatePayload>({
          queryFn: (arg, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.anomalieType.anomalieTypeCreate,
              arg,
            )
          },
        }),
        /* Cases */
        getCaseList: build.query<ResultatRechercheDossier, RechercheDossierListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.rechercheDossierList,
                args,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        getDepartmentList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.departementsList,
              )
            },
          },
        ),
        getDisasterList: build.query<NatureSinistre[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.naturesSinistresList,
              )
            },
          },
        ),
        getCaseInsuranceCompanyList: build.query<CompagnieAssurance[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.compagniesDAssuranceList,
              )
            },
          },
        ),
        /* Case */
        getCaseInfos: build.query<Dossier, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.dossierDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'INFO', id: '' },
            ],
          },
        ),
        getCaseWorkflow: build.query<WorkflowEntreprise, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.workflowEntrepriseDossierDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'WORKFLOW' },
            ],
          },
        ),
        patchInsuredInformation: build.mutation<void, {caseId: string; data: CoordoneesRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.coordonneesAssurePartialUpdate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'INFO' ],
          },
        ),
        patchWorkflowMissionState: build.mutation<void, {caseId: string; data: MissionnementEtatRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.etatMissionnementPartialUpdate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'INFO' ],
          },
        ),
        getRefuseMissionReasonList: build.query<MissionnementMotifRefus[], MissionnementMotifsRefusListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.missionnementMotifsRefus.missionnementMotifsRefusList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'INFO', id: '' },
              { type: 'WORKFLOW' },
            ],
          },
        ),
        /* Documents */
        getCaseDocumentList: build.query<Document[], DocumentDossierDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.documentDossierDetail,
                args,
              )
            },
          },
        ),
        postDocuments: build.mutation<void, { caseId: string; data: DocumentDossierCreatePayload }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.documentDossierCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'INFO' ],
          },
        ),
        deleteDocuments: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.document.documentDelete,
                args,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'INFO' ],
          },
        ),
        getCaseMediaList: build.query<ElementMediatheque[], MediathequeDossierDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.mediathequeDossierDetail,
                args,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'MEDIAS', id: 'LIST' },
              ...result.map((r) => ({ type: 'MEDIAS' as const, id: `${r.id}` })),
            ],
          },
        ),
        postMediaFiles: build.mutation<void, { caseId: string; data: MediathequeDossierCreatePayload }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.mediathequeDossierCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'MEDIAS', 'INFO' ],
          },
        ),
        deleteMediaFiles: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.mediatheque.mediathequeDelete,
                args,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'INFO', 'MEDIAS' ],
          },
        ),
        getDocumentCategoryList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.mediatheque.categorieMediathequeList,
              )
            },
          },
        ),
        /* Intervention */
        postIntervention: build.mutation<void, {caseId: string; data: InterventionDossierCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.interventionDossierCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW' ],
          },
        ),
        getInterventionReasonList: build.query<InterventionMotifNonReparation[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.intervention.motifInterventionList,
              )
            },
          },
        ),
        getInterventionResultList: build.query<ResultatInterventionEnumLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.intervention.resultatInterventionInterventionList,
              )
            },
          },
        ),
        getInterventionOriginRequestDeferred: build.query<OrigineDemandeInterventionDiffereeEnumLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.intervention.origineDemandeInterventionDiffereeInterventionList,
              )
            },
          },
        ),
        /* Contact */
        postContactRdv: build.mutation<void, {caseId: string; data: PriseDeContactRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.priseDeContactDossierCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'ISSUES' ],
          },
        ),
        /* Rendez-vous */
        postMeasureRdv: build.mutation<void, {caseId: string; data: RendezVousMetreurRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.metreurRendezVousCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'MEASURE_RDV' ],
          },
        ),
        patchMeasureRdv: build.mutation<void, {id: string; data: RendezVousMetreurRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.rendezVous.metreurRendezVousPartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'MEASURE_RDV' ],
          },
        ),
        postWorksRdv: build.mutation<void, {caseId: string; data: RendezVousTravauxRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.travauxRendezVousCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'WORKS_RDV' ],
          },
        ),
        patchWorksRdv: build.mutation<void, {id: string; data: RendezVousTravauxRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.rendezVous.travauxRendezVousPartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'WORKS_RDV' ],
          },
        ),
        /* Sinapps Report */
        postSinappsReport: build.mutation<void, {caseId: string; data: CompteRenduVisiteSinappsCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.sinapps.compteRenduVisiteSinappsCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW' ],
          },
        ),
        postSinappsWaterReport: build.mutation<void, {caseId: string; data: CompteRenduRdfSinappsCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.sinapps.compteRenduRdfSinappsCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW' ],
          },
        ),
        getDisasterNatureList: build.query<NestedCodeLabel[], string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.naturesCausesDetail,
                args,
              )
            },
          },
        ),
        getSinappsSelectList: build.query<CodeLabel[], {caseId: string; listType: EnumerationsMetier}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.listeSinappsDossierDetail,
                args.caseId,
                args.listType,
              )
            },
          },
        ),
        getIncreasedRiskList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.risquesAggravesList,
              )
            },
          },
        ),
        getOperationNatureList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.natureOperationList,
              )
            },
          },
        ),
        getComplexity: build.query<ComplexiteDDEEnumLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.complexiteRdfList,
              )
            },
          },
        ),
        /* Quote & Invoice */
        getFranchise: build.query<number, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.franchiseDossierDetail,
                args,
              )
            },
          },
        ),
        getRSE: build.query<number, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.rseDossierDetail,
                args,
              )
            },
          },
        ),
        getRemise: build.query<number, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.remiseDossierDetail,
                args,
              )
            },
          },
        ),
        getPriceList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.tarifsList,
              )
            },
          },
        ),
        getMeasureUnitList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.unitesMesureList,
              )
            },
          },
        ),
        getTVARateList: build.query<TauxTVA[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.tauxTvaList,
              )
            },
          },
        ),
        getLineTypeList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.typesDeLigneDevisList,
              )
            },
          },
        ),
        getArticleBordereauList: build.query<Bordereau[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.bordereauxCabinetList,
              )
            },
          },
        ),
        getDiverseBordereauList: build.query<Bordereau[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.bibliothequeDiversList,
              )
            },
          },
        ),
        /* Quote */
        getQuoteDraft: build.query<Devis, BrouillonDevisDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.brouillonDevisDetail,
                args,
              )
            },
          },
        ),
        postCalculQuoteEnd: build.mutation<PiedDevis, {data: DevisRequest; caseId: string}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.piedDevisCreate,
                args.caseId,
                args.data,
              )
            },
          },
        ),
        postSaveQuoteDraft: build.mutation<void, {caseId: string; data: DevisRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.brouillonDevisCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW' ],
          },
        ),
        postSaveQuote: build.mutation<void, {params: EditerDevisCreateParams; data: DevisRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.editerDevisCreate,
                args.params,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'INFO' ],
          },
        ),
        postImportQuote: build.mutation<Devis, {caseId: string; data: ImportDevisCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.importDevisCreate,
                args.caseId,
                args.data,
              )
            },
          },
        ),
        /* Invoice */
        getInvoiceDraft: build.query<FactureAPI, BrouillonFactureDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.brouillonFactureDetail,
                args,
              )
            },
          },
        ),
        postCalculInvoiceEnd: build.mutation<PiedFacture, {data: FactureRequest; caseId: string}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.piedFactureCreate,
                args.caseId,
                args.data,
              )
            },
          },
        ),
        postSaveInvoiceDraft: build.mutation<void, {caseId: string; data: FactureRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.brouillonFactureCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW' ],
          },
        ),
        postSaveInvoice: build.mutation<void, {params: EditerFactureCreateParams; data: FactureRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.editerFactureCreate,
                args.params,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'INFO' ],
          },
        ),
        postImportInvoice: build.mutation<void, {caseId: string; data: ImportFactureCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.importFactureCreate,
                args.caseId,
                args.data,
              )
            },
          },
        ),
        /* Internal Exchanges */
        getMessageList: build.query<EchangeInterne[], EchangeInterneListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.echangeInterneList,
                args,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'MESSAGES', id: 'LIST' },
              ...result.map((r) => ({ type: 'MESSAGES' as const, id: `${r.id}` })),
            ],
          },
        ),
        getMessagesRecipientList: build.query<CodeLabel[], DestinatairesEchangeInterneDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.destinatairesEchangeInterneDetail,
                args,
              )
            },
          },
        ),
        postNewMessage: build.mutation<void, {caseId: string; data: NouveauEchangeInterneCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.nouveauEchangeInterneCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        postAnswerMessage: build.mutation<void, {id: string; data: RepondreEchangeInterneCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.repondreEchangeInterneCreate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        postForwardMessage: build.mutation<void, {id: string; data: TransfertEchangeInterneCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.transfertEchangeInterneCreate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        patchMarkAsReadMessage: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.marquerCommeLuEchangeInterneUpdate,
                args,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        patchMarkAsUnreadMessage: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.marquerCommeNonLuEchangeInterneUpdate,
                args,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        /* Settings */
        getEurogexList: build.query<Eurogex[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.parametrage.eurogexParametrageList,
              )
            },
          },
        ),
        getEurogex: build.query<Eurogex, {section: string; rubrique: string}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.parametrage.eurogexParametrageDetail,
                args.section,
                args.rubrique,
              )
            },
          },
        ),
        /* Partners companies */
        getPartnerCompany: build.query<EntreprisePartenaire, number>(
          {
            queryFn: (async (args) => {
              return processRequest(
                OpenAPI.entreprisePartenaire.entreprisePartenaireDetail,
                args,
              )
            }),
            providesTags: (result, error, arg) => [
              { type: 'COMPANY' },
            ],  
          },
        ),
        patchPartnerCompany: build.mutation<void, {id: number; data: EntreprisePartenaireUpdatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.entreprisePartenaire.entreprisePartenaireUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'COMPANY' ],
          },
        ),
        /* Vacation */
        postVacation: build.mutation<number, {id: number; data: CongeEtablissementCreatePayload}>({
          queryFn: (async (arg) => {
            return processRequest(
              OpenAPI.etablissement.congeEtablissementCreate,
              arg.id,
              arg.data,
            )
          }),
          invalidatesTags: [ 'COMPANY' ],
        },
        ),
        deleteVacation: build.mutation<void, number>({
          queryFn: (async (arg) => {
            return processRequest(
              OpenAPI.etablissement.congeEtablissementDelete,
              arg,
            )
          }),
          invalidatesTags: [ 'COMPANY' ],
        },
        ),
        /* Statut juridique */
        getLegalStatus: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.statutJuridiqueList,
              )
            },
          },
        ),
        /* Nature inscription */
        getNatureInscription: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.natureInscriptionList,
              )
            },
          },
        ),
        /* Type contrat */
        getContractType: build.query<IdLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.contratEntrepriseList,
              )
            },
          },
        ),
      }
    },
  },
)

/* Export hooks */
export const {
  // Auth
  useAuthenticateMutation,
  useAuthenticateExternalMutation,
  useResetPasswordMutation,
  // Issue
  useGetIssueQuery,
  useLazyGetIssueQuery,
  useGetIssuesListQuery,
  useLazyGetIssuesListQuery,
  usePostIssueMutation,
  usePatchIssueMutation,
  //IssueType
  useGetIssueTypeQuery,
  useLazyGetIssueTypeQuery,
  useGetIssueTypesListQuery,
  useLazyGetIssueTypesListQuery,
  usePostIssueTypeMutation,
  usePatchIssueTypeMutation,
  // Cases
  useGetCaseListQuery,
  useLazyGetCaseListQuery,
  useGetDepartmentListQuery,
  useLazyGetDepartmentListQuery,
  useGetCaseInsuranceCompanyListQuery,
  useLazyGetCaseInsuranceCompanyListQuery,
  useGetDisasterListQuery,
  useLazyGetDisasterListQuery,
  // Case
  useGetCaseInfosQuery,
  useLazyGetCaseInfosQuery,
  useGetCaseWorkflowQuery,
  useLazyGetCaseWorkflowQuery,
  usePatchInsuredInformationMutation,
  usePatchWorkflowMissionStateMutation,
  useGetRefuseMissionReasonListQuery,
  useLazyGetRefuseMissionReasonListQuery,
  // Documents
  useGetCaseDocumentListQuery,
  useLazyGetCaseDocumentListQuery,
  usePostDocumentsMutation,
  useDeleteDocumentsMutation,
  useGetCaseMediaListQuery,
  useLazyGetCaseMediaListQuery,
  useGetDocumentCategoryListQuery,
  usePostMediaFilesMutation,
  useDeleteMediaFilesMutation,
  useLazyGetDocumentCategoryListQuery,
  // Intervention
  usePostInterventionMutation,
  useGetInterventionReasonListQuery,
  useLazyGetInterventionReasonListQuery,
  useGetInterventionResultListQuery,
  useLazyGetInterventionResultListQuery,
  useGetInterventionOriginRequestDeferredQuery,
  useLazyGetInterventionOriginRequestDeferredQuery,
  // Contact
  usePostContactRdvMutation,
  // Rendez-vous
  usePostMeasureRdvMutation,
  usePatchMeasureRdvMutation,
  usePostWorksRdvMutation,
  usePatchWorksRdvMutation,
  // Sinapps Report
  usePostSinappsReportMutation,
  usePostSinappsWaterReportMutation,
  useGetDisasterNatureListQuery,
  useLazyGetDisasterNatureListQuery,
  useGetSinappsSelectListQuery,
  useLazyGetSinappsSelectListQuery,
  useGetIncreasedRiskListQuery,
  useLazyGetIncreasedRiskListQuery,
  useGetOperationNatureListQuery,
  useLazyGetOperationNatureListQuery,
  useGetComplexityQuery,
  useLazyGetComplexityQuery,
  // Quote & Invoice
  useGetLineTypeListQuery,
  useLazyGetLineTypeListQuery,
  useGetFranchiseQuery,
  useLazyGetFranchiseQuery,
  useGetRSEQuery,
  useLazyGetRSEQuery,
  useGetRemiseQuery,
  useLazyGetRemiseQuery,
  useGetPriceListQuery,
  useLazyGetPriceListQuery,
  useGetMeasureUnitListQuery,
  useLazyGetMeasureUnitListQuery,
  useGetTVARateListQuery,
  useLazyGetTVARateListQuery,
  useGetArticleBordereauListQuery,
  useLazyGetArticleBordereauListQuery,
  useGetDiverseBordereauListQuery,
  useLazyGetDiverseBordereauListQuery,
  // Quote
  useGetQuoteDraftQuery,
  useLazyGetQuoteDraftQuery,
  usePostCalculQuoteEndMutation,
  usePostSaveQuoteDraftMutation,
  usePostSaveQuoteMutation,
  usePostImportQuoteMutation,
  // Invoice
  useGetInvoiceDraftQuery,
  useLazyGetInvoiceDraftQuery,
  usePostCalculInvoiceEndMutation,
  usePostSaveInvoiceDraftMutation,
  usePostSaveInvoiceMutation,
  usePostImportInvoiceMutation,
  // Internal Exchanges
  useGetMessageListQuery,
  useLazyGetMessageListQuery,
  useGetMessagesRecipientListQuery,
  useLazyGetMessagesRecipientListQuery,
  usePostNewMessageMutation,
  usePostAnswerMessageMutation,
  usePostForwardMessageMutation,
  usePatchMarkAsReadMessageMutation,
  usePatchMarkAsUnreadMessageMutation,
  // Settings
  useGetEurogexListQuery,
  useLazyGetEurogexListQuery,
  useGetEurogexQuery,
  useLazyGetEurogexQuery,
  // Partner companie
  useGetPartnerCompanyQuery,
  useLazyGetPartnerCompanyQuery,
  usePatchPartnerCompanyMutation,
  // Vacation
  usePostVacationMutation,
  useDeleteVacationMutation,
  // Nature inscription
  useGetNatureInscriptionQuery,
  useLazyGetNatureInscriptionQuery,
  // Statut juridique
  useGetLegalStatusQuery,
  useLazyGetLegalStatusQuery,
  // Type contrat
  useGetContractTypeQuery,
  useLazyGetContractTypeQuery,
} = api
