/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import SettingsLayoutSummaryEditModal from './SettingsLayoutSummaryEditModal'

/* Type declarations -------------------------------------------------------- */
import type { EntreprisePartenaire } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const BoldSeparator = styled.div`
  width: 200% !important;
  margin: 15px -15px 5px -15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const EditButton = styled(Button)`
  height: 30px;
  text-decoration: underline;
`

/* Component declaration ---------------------------------------------------- */
interface SettingsLayoutSummaryProps {
  summary: EntreprisePartenaire;
}

const SettingsLayoutSummary: React.FC<SettingsLayoutSummaryProps> = ({ summary }) => {
  const [ openEditModal, setOpenEditModal ] = useState<boolean>(false)

  return (
    <SummaryContainer>
      <BoldTitle>
        Informations Générales
        <EditButton onClick={() => setOpenEditModal(true)}>
          Modifier
        </EditButton>
      </BoldTitle>
      <BoldTitle>
        Informations Société
      </BoldTitle>
      <div>
        {'Raison Sociale : '}
        <b>
          {summary.raisonSociale}
        </b>
      </div>
      <div>
        {'Date d\'Immatriculation : '}
        <b>
          {DateUtils.APIStrToLocalDateString(summary.dateImmatriculation)}
        </b>
      </div>
      <div>
        {'SIREN : '}
        <b>
          {summary.siren}
        </b>
      </div>
      <div>
        {'Code APE : '}
        <b>
          {summary.codeApe}
        </b>
      </div>
      <div>
        {'Numéro TVA Intracommunautaire : '}
        <b>
          {summary.numeroTvaIntracommunication}
        </b>
      </div>
      <div>
        {'Code Entreprise : '}
        <b>
          {summary.codeEntreprise}
        </b>
      </div>
      <div>
        {'Code Forme Juridique : '}
        <b>
          {summary.codeFormeJuridique}
        </b>
      </div>
      <div>
        {'Type contrat : '}
        <b>
          {summary.typeContratPartenaire?.libelle}
        </b>
      </div>
      <div>
        {'Statut Juridique : '}
        <b>
          {summary.donneesAvancees?.statutJuridique?.libelle}
        </b>
      </div>
      <div>
        {'Nature d\'Inscription : '}
        <b>
          {summary.donneesAvancees?.natureInscription?.libelle}
        </b>
      </div>
      <div>
        {'Codification Assureur : '}
        <b>
          {summary.donneesAvancees?.codificationAssureur}
        </b>
      </div>
      <div>
        {'Numéro RCS/RDM : '}
        <b>
          {summary.donneesAvancees?.numeroRCSRDM}
        </b>
      </div>
      <div>
        {'Capital Social : '}
        <b>
          {summary.capitalSocial}
        </b>
      </div>
      <BoldSeparator />
      <BoldTitle>
        Siège Social
      </BoldTitle>
      <div>
        {'Adresse : '}
        <b>
          {getAddress(summary.siegeSocialCoordonees.adresse)}
        </b>
      </div>
      <div>
        {'Code Postal : '}
        <b>
          {summary.siegeSocialCoordonees?.adresse?.codePostal}
        </b>
      </div>
      <div>
        {'Ville : '}
        <b>
          {summary.siegeSocialCoordonees?.adresse?.ville}
        </b>
      </div>
      <div>
        {'Code Pays : '}
        <b>
          {summary.siegeSocialCoordonees?.adresse?.codePays}
        </b>
      </div>
      <div>
        {'Email : '}
        <a
          href={`mailto:${summary.siegeSocialCoordonees?.email}`}
          target="_self"
        >
          {summary.siegeSocialCoordonees?.email}
        </a>
      </div>
      <BoldSeparator />
      <BoldTitle>
        Informations dirigeant
      </BoldTitle>
      <div>
        {'Nom : '}
        <b>
          {summary.dirigeantNom}
        </b>
      </div>
      <div>
        {'Prénom : '}
        <b>
          {summary.dirigeantPrenom}
        </b>
      </div>
      <div>
        {'Téléphone : '}
        <a
          href={`tel:${summary.dirigeantTel}`}
          target="_self"
        >
          {summary.dirigeantTel}
        </a>
      </div>
      {
        openEditModal &&
          <SettingsLayoutSummaryEditModal
            open
            handleClose={() => setOpenEditModal(false)}
            company={summary}
          />
      }
    </SummaryContainer>
  )
}

export default SettingsLayoutSummary
