/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'

/* Type declarations -------------------------------------------------------- */
export interface EstablishmentState {
  establishmentId: number | null;
}

/* Redux slice -------------------------------------------------------------- */
const establishmentSlice = createSlice(
  {
    name: 'establishment',
    initialState: {
      establishmentId: null,
    } as EstablishmentState,
    reducers: {
      setEstablishmentId: (state, { payload }: PayloadAction<number>) => {
        state.establishmentId = payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { setEstablishmentId } = establishmentSlice.actions

export default establishmentSlice.reducer

export const getEstablishmentId = (state: RootState): number | null => {
  return state.establishment.establishmentId
}
