/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetMessageListQuery } from 'store/api'
import { useIsDesktop } from 'helpers/hooks/useIsMedia'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material'
import MessagesPage from 'pages/MessagesPage/MessagesPage'
import MessagesContainerCard from './MessagesContainerComponents/MessagesContainerCard'

/* Styled components -------------------------------------------------------- */
const MessagesContainerPageContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  @media ${(props) => props.theme.media.desktop}, ${(props) => props.theme.media.tablet} {
    display: contents;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const CardMobileContainer = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const OutletDesktopContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.desktop} {
    display: flex;
    gap: 30px;

    & > div:nth-of-type(2) {
      width: 100%;
    }
  }
`

const OutletMobileContainer = styled.div`
  display: block;
  padding-top: 10px;

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const ResumeCardContainer = styled(Card)`
  display: none;
  width: 30vw !important;
  padding: 10px 15px;

  @media ${(props) => props.theme.media.desktop} {
    display: initial;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    position: sticky;
    top: 0px;
    font-size: .9rem;
    height: fit-content;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`

const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

/* Component declaration ---------------------------------------------------- */
interface MessagesContainerPageProps {}

const MessagesContainerPage: React.FC<MessagesContainerPageProps> = () => {
  const isDesktop = useIsDesktop()
  const [ mobileMenuTabValue, setMobileMenuTabValue ] = useState<number>(1)
  const [ selectedCaseId, setSelectedCaseId ] = useState<string>('')

  const { currentData: messages = []} = useGetMessageListQuery({})

  return (
    <MessagesContainerPageContainer>
      <CardMobileContainer>
        <Tabs
          value={mobileMenuTabValue}
          onChange={(e, newValue: number) => setMobileMenuTabValue(newValue)}
          variant="fullWidth"
        >
          {
            [ 'Liste des dossiers', 'Messagerie' ].map((tab, index) => (
              <Tab
                key={`${tab}-${index}`}
                label={tab}
              />
            ))
          }
        </Tabs>
      </CardMobileContainer>
      <div>
        {
          isDesktop ?
            <OutletDesktopContainer>
              <ResumeCardContainer>
                {
                  !messages.length ?
                    <Loader>
                      <CircularProgress color="secondary" />
                    </Loader> :
                    <MessagesContainerCard
                      messages={messages}
                      setSelectedCaseId={setSelectedCaseId}
                    />
                }
              </ResumeCardContainer>
              <div>
                <MessagesPage externalCaseId={selectedCaseId} />
              </div>
            </OutletDesktopContainer> :
            <OutletMobileContainer>
              {
                mobileMenuTabValue === 0 ?
                  (
                    !messages.length ?
                      <LoaderContainer>
                        <CircularProgress size={60} />
                      </LoaderContainer> :
                      <MessagesContainerCard
                        messages={messages}
                        setSelectedCaseId={setSelectedCaseId}
                      />
                  ) :
                  (
                    <MessagesPage externalCaseId={selectedCaseId} />
                  )
              }
            </OutletMobileContainer>
        }
      </div>
    </MessagesContainerPageContainer>
  )
}

export default MessagesContainerPage
