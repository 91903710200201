/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetContractTypeQuery,
  useGetLegalStatusQuery,
  useGetNatureInscriptionQuery,
  usePatchPartnerCompanyMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import {
  Select,
  TextField,
} from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AddressAutocomplete from 'components/FieldWithInputAdornment/AddressAutocomplete'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Feature } from 'geojson'
import type {
  AdresseRequest,
  CoordoneesRequest,
  EntreprisePartenaire,
  EntreprisePartenaireUpdatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type CompanyForm = FormikContextType<EntreprisePartenaireUpdatePayload>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: -20px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
`

const DoubleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

const BlankDiv = styled.div`
  height: 10px;
`

const BoldSeparator = styled.div`
  width: 100% !important;
  margin: 30px 0px 0px 0px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface SettingsLayoutSummaryEditModalProps {
  open: boolean;
  handleClose: () => void;
  company: EntreprisePartenaire;
}

const SettingsLayoutSummaryEditModal: React.FC<SettingsLayoutSummaryEditModalProps> = ({
  open,
  handleClose,
  company,
}) => {
  const {
    currentData: natureInscriptionList = [],
    isFetching: isFetchingNatureInscription,
  } = useGetNatureInscriptionQuery()
  const {
    currentData: legalStatusList = [],
    isFetching: isFetchingLegalStatusList,
  } = useGetLegalStatusQuery()
  const {
    currentData: contractTypeList = [],
    isFetching: isFetchingContractTypeList,
  } = useGetContractTypeQuery()
  const [
    submitUpdatePartnerCompany,
  ] = usePatchPartnerCompanyMutation()

  const onSubmit = (values: EntreprisePartenaireUpdatePayload, { setSubmitting }: FormikHelpers<EntreprisePartenaireUpdatePayload>) => {
    submitUpdatePartnerCompany({ id: company.id, data: values })
      .then((response) => {
        if (!isApiError(response)) {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }
  const formikForm: CompanyForm = useForm<EntreprisePartenaireUpdatePayload>(
    {
      onSubmit: onSubmit,
      initialValues: {
        ...company,
        dateImmatriculation: company.dateImmatriculation || '',
        siegeSocialCoordonees: {
          ...company.siegeSocialCoordonees,
          adresse: {
            ...company.siegeSocialCoordonees.adresse,
            adresse1: company.siegeSocialCoordonees.adresse?.adresse1 || '',
            codePostal: company.siegeSocialCoordonees.adresse?.codePostal || '',
            ville: company.siegeSocialCoordonees.adresse?.ville || '',
          },
        },
        donneesAvancees: {
          ...company.donneesAvancees,
          natureInscription: company.donneesAvancees?.natureInscription?.code,
          statutJuridique: company.donneesAvancees?.statutJuridique?.code,
        },
        raisonSociale: company.raisonSociale || '',
        idTypeContratPartenaire: company.typeContratPartenaire?.id || 0,
      },
    },
  )

  const onAddressChange = (newAddress: Feature) => {
    formikForm.setValues({
      ...formikForm.values,
      siegeSocialCoordonees: {
        adresse: {
          ...formikForm.values.siegeSocialCoordonees?.adresse,
          adresse1: newAddress.properties?.name as string,
          codePostal: newAddress.properties?.postcode as string,
          ville: newAddress.properties?.city as string,
        },
      },
    })
  }

  const addressErrorProps = () => {
    const addressTouched = ((formikForm.touched.siegeSocialCoordonees as unknown as CoordoneesRequest)?.adresse as unknown as AdresseRequest)?.adresse1
    const addressError = ((formikForm.touched.siegeSocialCoordonees as unknown as CoordoneesRequest)?.adresse as unknown as AdresseRequest)?.adresse1
    return ({
      error: addressTouched && addressError !== undefined,
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Informations générales
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContentContainer>
          <FormBoldTitle>
            Société
          </FormBoldTitle>
          <div>
            <FormBoldTitle>
              Raison sociale
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Raison Sociale"
              name="raisonSociale"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Date d'immatriculation
            </FormBoldTitle>
            <FormikDatePicker name="dateImmatriculation" />
          </div>
          <div>
            <FormBoldTitle>
              SIREN
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="SIREN"
              name="siren"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Code APE
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Code APE"
              name="codeApe"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Numéro TVA intracommunautaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Numéro TVA Intracommunautaire"
              name="numeroTvaIntracommunication"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Code entreprise
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Code Entreprise"
              name="codeEntreprise"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Code forme juridique
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Code Entreprise"
              name="codeEntreprise"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Type contrat
            </FormBoldTitle>
            <Field
              component={Select}
              placeholder="Type contrat"
              name="idTypeContratPartenaire"
              displayEmpty
              disabled={isFetchingContractTypeList}
              size="small"
            >
              <MenuItem value="">
                Sélectionner
              </MenuItem>
              {
                contractTypeList.map((value, index) => (
                  <MenuItem
                    value={value.id}
                    key={`${value.id}-${index}`}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
          </div>
          <div>
            <FormBoldTitle>
              Statut juridique
            </FormBoldTitle>
            <Field
              component={Select}
              placeholder="Statut Juridique"
              name="donneesAvancees.statutJuridique"
              displayEmpty
              disabled={isFetchingLegalStatusList}
              size="small"
            >
              <MenuItem value="">
                Sélectionner
              </MenuItem>
              {
                legalStatusList.map((value, index) => (
                  <MenuItem
                    value={value.code || ''}
                    key={`${value.code}-${index}`}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
          </div>
          <div>
            <FormBoldTitle>
              Nature d'Inscription
            </FormBoldTitle>
            <Field
              component={Select}
              placeholder="Nature d'Inscription"
              name="donneesAvancees.natureInscription"
              displayEmpty
              disabled={isFetchingNatureInscription}
              size="small"
            >
              <MenuItem value="">
                Sélectionner
              </MenuItem>
              {
                natureInscriptionList.map((value, index) => (
                  <MenuItem
                    value={value.code || ''}
                    key={`${value.code}-${index}`}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
          </div>
          <div>
            <FormBoldTitle>
              Codification assureur
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Codification assureur"
              name="donneesAvancees.codificationAssureur"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Numéro RCS/RDM
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Numéro RCS/RDM"
              name="donneesAvancees.numeroRCSRDM"
              size="small"
            />
          </div>
          <BoldSeparator />
          <FormBoldTitle>
            Siège Social
          </FormBoldTitle>
          <FormBoldTitle>
            Adresse
          </FormBoldTitle>
          <AddressAutocomplete
            placeholder="Adresse"
            value={formikForm.values.siegeSocialCoordonees?.adresse?.adresse1 || ''}
            onValueChange={(newValue: string) => formikForm.setFieldValue('siegeSocialCoordonees.adresse.adresse1', newValue)}
            onAddressChange={onAddressChange}
            size="small"
            {...addressErrorProps}
          />
          <BlankDiv />
          <Field
            component={TextField}
            placeholder="Adresse"
            name="siegeSocialCoordonees.adresse.adresse2"
            size="small"
          />
          <BlankDiv />
          <Field
            component={TextField}
            placeholder="Adresse"
            name="siegeSocialCoordonees.adresse.adresse3"
            size="small"
          />
          <DoubleContainer>
            <div>
              <FormBoldTitle>
                Code postal
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Code postal"
                name="siegeSocialCoordonees.adresse.codePostal"
                size="small"
              />
            </div>
            <div>
              <FormBoldTitle>
                Ville
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Ville"
                name="siegeSocialCoordonees.adresse.ville"
                size="small"
              />
            </div>
          </DoubleContainer>
          <div>
            <FormBoldTitle>
              Email
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Email"
              name="siegeSocialCoordonees.email"
              size="small"
            />
          </div>
          <BoldSeparator />
          <FormBoldTitle>
            Informations dirigeant
          </FormBoldTitle>
          <div>
            <FormBoldTitle>
              Nom
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Nom de dirigeant"
              name="dirigeantNom"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Prénom
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Prenom de dirigeant"
              name="dirigeantPrenom"
              size="small"
            />
          </div>
          <div>
            <FormBoldTitle>
              Téléphone
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Téléphone de dirigeant"
              name="dirigeantTel"
              size="small"
            />
          </div>
        </DialogContentContainer>
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default SettingsLayoutSummaryEditModal
