/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetPartnerCompanyQuery } from 'store/api'
import {
  useAppSelector,
  useAuthInfo,
} from 'store/hooks'
import { getEstablishmentId } from 'store/slices/establishmentSlice'

/* Component imports -------------------------------------------------------- */
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import NewVacationButton from './VacationsComponents/VacationsCardButton'
import VacationsCard from './VacationsComponents/VacationsCard'

/* Styled components -------------------------------------------------------- */
interface SpaceBetweenContainerProps {
  last?: boolean;
}

const SpaceBetweenContainer = styled.div<SpaceBetweenContainerProps>`
  padding-bottom: ${(props) => props.last ? '0px' : '20px'};
`

/* Component declaration ---------------------------------------------------- */
interface VacationPageProps {}

const VacationPage: React.FC<VacationPageProps> = () => {
  const auth = useAuthInfo()
  const establishmentId = useAppSelector(getEstablishmentId)

  const { currentData: companyDetails } = useGetPartnerCompanyQuery(auth?.id as number ?? -1)

  return (
    <div>
      <LargeTitle>
        Congés
        {
          establishmentId &&
            <NewVacationButton establishmentId={establishmentId} />
        }
      </LargeTitle>
      {
        (auth?.id && establishmentId) && companyDetails?.etablissements?.find((et) => et.id === establishmentId)?.conges.map((vac, index, array) => (
          <SpaceBetweenContainer
            last={index === array.length}
            key={vac.id}
          >
            <VacationsCard
              vacation={vac}
              establishmentId={establishmentId}
            />
          </SpaceBetweenContainer>
        ))
      }
      <Footer />
    </div>
  )
}

export default VacationPage
